import Vue from 'vue';
import moment from 'moment-timezone';
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from 'vee-validate';
import {
  required,
  email,
  max,
  // eslint-disable-next-line camelcase
  max_value,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  digits,
  numeric,
  regex
} from 'vee-validate/dist/rules';

export const uuidV4Regex =
  '^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[89abAB]{1}[a-fA-F0-9]{3}-[a-fA-F0-9]{12}$';

export const fqdnRegex =
  /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;

/**
 * Represents max email content size expressed in bytes.
 */
export const MAX_EMAIL_CONTENT_SIZE = 26214400;

extend('email', email);
extend('required', required);
extend('required_explicit', required);
extend('one_required', required);
extend('max', max);
extend('min', min);
extend('max_value', max_value);
extend('min_value', min_value);
extend('digits', digits);
extend('at_least_one', required);
extend('numeric', numeric);
extend('regex', regex);

extend('min_donation', {
  params: ['minValue', 'currency'],
  validate(value, { minValue }) {
    return parseInt(value) >= parseInt(minValue);
  }
});

extend('max_quantity', (value) => {
  return parseInt(value) <= 100000;
});

extend('organizer_name', (value) => {
  return /^(?!.*-)(?=.*?[\D])[a-z0-9-]{2,40}$/.test(value);
});

extend('organizer_display_name', (value) => {
  return /^[-A-Za-z0-9 ,.:!']{2,40}$/.test(value);
});

extend('username', (value) => {
  return /^[a-zA-Z0-9-_.]{3,40}$/.test(value);
});

extend('valid_link', (value) => {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g.test(
    value
  );
});

extend('description_min', (value) => {
  const content = value.trim().replace(/<\/?[^>]+(>|$)/g, '');
  return /^(.{5,})/.test(content);
});

extend('coordinates', (value) => {
  try {
    return /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(value);
  } catch (e) {
    return false;
  }
});
extend('confirm_password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  }
});
extend('password', (value) => {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value);
});
extend('min_date', {
  params: ['targetField', 'minDate'],
  validate(value, { minDate }) {
    const momentValue = moment(value);
    const momentTarget = moment(minDate);
    return momentValue.isSameOrAfter(momentTarget);
  }
});
extend('max_date', {
  params: ['targetField', 'maxDate'],
  validate(value, { maxDate }) {
    const momentValue = moment(value);
    const momentTarget = moment(maxDate);
    return momentValue.isSameOrBefore(momentTarget);
  }
});
extend('wallet_id', {
  params: ['walletId'],
  validate(value) {
    const regex = new RegExp(uuidV4Regex);
    return regex.test(value);
  }
});

extend('email_content_size', {
  validate(value) {
    const blob = new Blob([value]);
    return blob.size < MAX_EMAIL_CONTENT_SIZE;
  }
});

extend('fqdn', {
  params: ['fqdn'],
  validate(value) {
    return fqdnRegex.test(value);
  }
});

setInteractionMode('eager');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
