export const emailIsValid = (email: string) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
    email
  );

export const usernameIsValid = (username: string) =>
  /^[a-zA-Z0-9-_.]{3,40}$/.test(username);

export const usernameOrEmailIsValid = (userInput: string) => {
  if (userInput.includes('@')) {
    return emailIsValid(userInput);
  } else {
    return usernameIsValid(userInput);
  }
};

export const passwordIsValid = (password: string) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password);

export const organizerNameIsValid = (organizerName: string) =>
  /^(?!.*-)(?=.*?[\D])[a-z0-9-]{2,40}$/.test(organizerName);

export const organizerDisplayNameIsValid = (organizerDisplayName: string) =>
  /^[-A-Za-z0-9 ,.:!']{2,40}$/.test(organizerDisplayName);

export const linkIsValid = (link: string) =>
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g.test(
    link
  );

export const numberIsValid = (number: string) =>
  /^[0-9]\d*(\.\d+)?$/.test(number);

export const isInteger = (number: string) => /^[0-9]+([0-9]+)?$/.test(number);

export const phoneNumberIsValid = (number: string) =>
  /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/g.test(
    number
  );

export const isEmpty = (value: string) => /^\s*$/.test(value);

export const maxValue = (value: string, maxValue: string) =>
  parseFloat(value) <= parseFloat(maxValue);

export const minValue = (value: string, minValue: string) =>
  parseFloat(value) >= parseFloat(minValue);

export const maxTicketQuantity = (value: string) => parseFloat(value) <= 100000;
